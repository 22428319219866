import {
    GET_ALL_POSTS,
    GET_POST_DETAIL
} from "../constants/action-types";

const initialState = {
    posts: [],
    currentPage: 1,
    totalPages: 1,
    postDetail: {},
    totalPosts: 0,
};

const postReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_POSTS:
            return {
                ...state,
                posts: action.posts,
                currentPage: action.currentPage,
                totalPages: action.totalPages,
                totalPosts: action.totalPosts,
            };
        case GET_POST_DETAIL:
            return {
                ...state,
                postDetail: action.postDetail
            };
        default:
            return state;
    }
};

export default postReducer;