import axios from 'axios';
import {CLEAR_CART_LIST, GET_CART_LIST, REFRESH_CART_LIST, GET_MY_ORDERS_LIST} from "../constants/action-types";
import store from '../store';
import {toast} from "react-toastify";

//get all products in cart list
export const getCartList = () => async dispatch => {
    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }
        const res = await axios.get(`${process.env.REACT_APP_API_URL}/cart`, config);

        dispatch({
            type: GET_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,
        });
    }
}

//clear cart list when user logout
export const clearCashList = () => async dispatch => {
    dispatch({
        type: CLEAR_CART_LIST,
        cartlist_variants: [],
        cartlist_packs: [],
        total: 0,
        sellingPrice: 0
    });
}


export const addVariantToCartlist = (id, qty, reset) => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }

        }

        let params = {
            qty,
            reset
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/variant/${id}`, params, config);

        dispatch({
            type: REFRESH_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,

        });
    } else {
        toast.warning("Vous devez etre connectés!");
    }
}


export const addPackToCartlist = (id, qty, reset) => async dispatch => {

    const token = store.getState().user.token;

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    let params = {
        qty,
        reset
    }

    const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/pack/${id}`, params, config);

    dispatch({
        type: REFRESH_CART_LIST,
        cartlist_variants: res.data.variants,
        cartlist_packs: res.data.packs,
        total: res.data.stats.total,
        sellingPrice: res.data.stats.sellingPrice,

    });

}


export const removeVariantFromCartlist = (id) => async dispatch => {

    const token = store.getState().user.token;

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    const res = await axios.delete(`${process.env.REACT_APP_API_URL}/cart/variant/${id}`, config).then(toast.success("Successfully removed from cart"));

    dispatch({
        type: REFRESH_CART_LIST,
        cartlist_variants: res.data.variants,
        cartlist_packs: res.data.packs,
        total: res.data.stats.total,
        sellingPrice: res.data.stats.sellingPrice,
    })

}


export const removePackFromCartlist = (id) => async dispatch => {
    const token = store.getState().user.token;

    let config = {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    }

    const res = await axios.delete(`${process.env.REACT_APP_API_URL}/cart/pack/${id}`, config);

    dispatch({
        type: REFRESH_CART_LIST,
        cartlist_variants: res.data.variants,
        cartlist_packs: res.data.packs,
        total: res.data.stats.total,
        sellingPrice: res.data.stats.sellingPrice,

    });
}

// cart list checkout
export const cartListSaveOrder = (fullname, phone, email, adressl1, adressl2) => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        let params = {
            "line1": adressl1,
            "line2": adressl2,
            "fullName": fullname,
            "email": email,
            "phoneNumber": phone
        }

        const res = await axios.post(`${process.env.REACT_APP_API_URL}/cart/checkout`, params, config);

        console.log("Cart Save Res : ", res);

        /*dispatch({
            type: GET_CART_LIST,
            cartlist_variants: res.data.variants,
            cartlist_packs: res.data.packs,
            total: res.data.stats.total,
            sellingPrice: res.data.stats.sellingPrice,
        });*/
    }
}

// cart list get orders
export const getCartListOrders = () => async dispatch => {

    if (store.getState().user.isLogged) {

        const token = store.getState().user.token;

        let config = {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }

        const res = await axios.get(`${process.env.REACT_APP_API_URL}/orders/my-orders`, config);

        dispatch({
            type: GET_MY_ORDERS_LIST,
            my_orders: res.data
        });
    }
}