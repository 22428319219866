import axios from 'axios';
import {
    GET_ALL_POSTS,
    GET_POST_DETAIL
} from "../constants/action-types";



export const getAllPosts = (p) => async dispatch => {

    let metadata = {
        "pagination": {
            "page": p
        },
        "sort": {
            "field": "createdAt",
            "asc": false
        },
        "filters": {
            "search": "",
        },
        "columns": {
            "*": []
        }
    }

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts`, {params: {meta: metadata}});

    dispatch({
        type: GET_ALL_POSTS,
        posts: res.data.entities,
        currentPage: res.data.meta.pagination.page,
        totalPages: res.data.meta.pagination.pages,
        totalPosts: res.data.meta.pagination.total
    });

}


export const getPostDetail = (id) => async dispatch => {

    const res = await axios.get(`${process.env.REACT_APP_API_URL}/posts/${id}`);

    dispatch({
        type: GET_POST_DETAIL,
        postDetail: res.data
    });
}
